import React, { useState, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import image01 from 'assets/home/testimonails/testimonial-01.png'
import image02 from 'assets/home/testimonails/testimonial-02.png'
import image03 from 'assets/home/testimonails/testimonial-03.png'
import manjunath from 'assets/home/testimonails/manjunath.mp4'
import manglam from 'assets/home/testimonails/manglam_city.mp4'
import jeevanRekha from 'assets/home/testimonails/jeevan_rekha.mp4'

const data = [
  {
    coverImage: image01,
    title: 'Dr. Sanjeeb Roy',
    degree: 'M.D, D.M (Cardiology)',
    place: 'Mangalam Plus Medicity',
    desig: 'Chairman- Cardiac Science, Director &HOD- Cardiology',
    videoFile: manglam,
  },

  {
    coverImage: image02,
    title: 'Dr. CN Manjunathan',
    degree: '',
    place: 'Sri Jayadeva Institute of Cardiovascular Sciences and Research',
    desig: 'Dean & Director',
    videoFile: manjunath,
  },
  {
    coverImage: image03,
    title: 'Mr. Ram Chitlangia',
    degree: 'M.D, D.M (Cardiology)',
    place: '',
    desig: 'Consultant Cardiologist',
    videoFile: jeevanRekha,
  },
]

export default function Testimonails() {
  const [isOpen, setIsOpen] = useState(false)
  const [videoIndex, setVideoIndex] = useState<number | undefined>(undefined)
  const videoRef = useRef<HTMLVideoElement>(null)
  const handleVideo = () => {
    if (videoRef.current) {
      videoRef.current.requestFullscreen()
      videoRef.current.play()
    }
  }

  const handlePlay = () => {
    setIsOpen(true)
  }

  const closeModal = () => {
    setIsOpen(false)
    if (videoRef.current) {
      videoRef.current.pause()
    }
  }

  const imgWrapper = useRef<HTMLImageElement>(null)
  const btnRef = useRef<HTMLDivElement>(null)

  const handleModalReveal = (index: number) => {
    setVideoIndex(index)
    setIsOpen(true)
  }

  const handleModalClose = (index: number) => {
    setVideoIndex(undefined)
    setIsOpen(false)
  }
  return (
    <section className="blade-top-padding-lg blade-bottom-padding-lg">
      <section className="w-[93%] mx-auto border-1 border-solid border-gray">
        <div className="py-5 lg:py-10 text-center bg-[url('assets/home/testimonails/mesh2.svg')] bg-contain bg-center grid place-content-center place-items-center">
          <h3 className="text-orange font-medium">Experts recommend us</h3>
        </div>
        <div className="border-t-1 border-solid border-gray ">
          <div className=" grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:w-10/12 xl:w-11/12 gap-y-5 md:gap-4 mx-auto w-container  gap-4 xl:gap-5 2xl:gap-10 blade-top-padding-lg blade-bottom-padding-lg">
            {data.map((elem, index) => {
              const key = `${index}`
              const { coverImage, title, degree, place, desig } = elem
              return (
                <Card
                  key={key}
                  coverImage={coverImage}
                  index={index}
                  title={title}
                  degree={degree}
                  place={place}
                  desig={desig}
                  toggleModal={handleModalReveal}
                />
              )
            })}
          </div>
        </div>
      </section>
      <Transition appear show={isOpen} as={React.Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />
            </Transition.Child>

            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-4xl h-auto border-4 border-solid  border-white my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-md">
                <div className="flex flex-col h-full  oveflow-hidden relative">
                  <div className="flex-0 grid place-content-end absolute top-2 z-10 right-2 left-auto bottom-auto">
                    <button
                      type="submit"
                      onClick={closeModal}
                      className=" grid place-content-center bg-white p-3 hover:bg-lightOrange focus-visible:bg-lightOrange rounded-full"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                  <div className="flex-1  overflow-hidden rounded-md">
                    {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                    <video
                      ref={videoRef}
                      controls
                      className="w-full h-full object-cover object-center"
                      src={data[videoIndex ?? 0].videoFile}
                      autoPlay
                      loop
                    />
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </section>
  )
}

function Card({
  title,
  degree,
  place,
  desig,
  index,
  coverImage,
  toggleModal,
}: {
  degree: string
  place: string
  desig: string
  coverImage: string
  index: number
  toggleModal: (update: number) => void
  title: string
}) {
  return (
    <article
      data-aos-delay={100 * index}
      data-aos="zoom-in"
      data-aos-duration="700"
      className="border-1 border-solid border-orange border-opacity-60"
    >
      <div className="relative  h-52 2xl:h-60">
        <div className="absolute inset-0 grid place-content-end p-4">
          <button
            type="button"
            onClick={() => toggleModal(index)}
            className="fill-black hover:fill-orange outline-offset-2 focus-visible:outline-orange outline-transparent outline-2  bg-white rounded-full focus-visible:fill-orange h-14 w-14 grid place-content-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 20 24"
              className="translate-x-[2px] transition-all duration-300 ease-in-out"
              fill="inherit"
            >
              <path
                d="M19.6982 11.9684L0.0207509 23.3292L0.0207519 0.607619L19.6982 11.9684Z"
                fill="inherit"
              />
            </svg>
          </button>
        </div>

        <img
          src={coverImage}
          alt={title}
          className="h-full w-full object-cover object-top"
        />
      </div>
      <div className="flex flex-col gap-[2px] pt-6 pb-4 px-3">
        <h5 className="font-medium pb-1">{title} </h5>
        {degree && (
          <span className="font-regular text-sm md:text-base ">{degree}</span>
        )}
        {place && (
          <span className="font-regular text-sm md:text-base block max-w-[300px]">
            {place}{' '}
          </span>
        )}
        {desig && (
          <span className="font-regular text-sm md:text-base ">{desig} </span>
        )}
      </div>
    </article>
  )
}
