import React from 'react'

const sizeMap = {
  small: 'text-sm',
  base: 'text-base',
  large: ' text-base md:text-lg',
  xl: ' text-base md:text-xl',
  xxl: ' text-xl xl:text-2xl',
  '3xl': ' text-xl md:text-2xl xl:text-3xl',
}
const colorMap = {
  white: 'text-black bg-white',
  orange: 'text-white bg-orange',
}

type MasterBtnProps<T extends 'submit' | 'button'> = {
  text: string
  color: 'orange' | 'white'
  size: keyof typeof sizeMap
  type: T
  isDisabled?: boolean
  onClick?: T extends 'submit' ? never : () => void
}

export function MasterBtn<T extends 'submit' | 'button'>({
  type,
  text,
  size,
  isDisabled,
  isOutline = true,
  ...rest
}: MasterBtnProps<T> & { isOutline?: boolean }) {
  const sizeClass: string = sizeMap[size]
  return (
    <button
      disabled={isDisabled}
      {...rest}
      type={type === 'button' ? 'button' : 'submit'}
      className={`rounded-md border-1 font-regular font-semibold tracking-wider stroke-orange hover:stroke-white focus-visible:stroke-white focus-visible:text-white hover:fill-white focus-visible:fill-white fill-orange ${
        isOutline
          ? 'border-black hover:border-transparent focus-visible:border-transparent'
          : 'border-transparent'
      } border-solid text-black hover:text-white outline-none px-5 py-3 flex items-center gap-3 hover:bg-orange focus-visible:bg-orange transition-colors duration-300 ease-in-out ${sizeClass} `}
    >
      {text}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="inherit"
        className="w-6 h-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
        />
      </svg>
    </button>
  )
}

export function FilledBtn<T extends 'submit' | 'button'>({
  type,
  text,
  size,
  color = 'orange',
  isDisabled,
  ...rest
}: MasterBtnProps<T>) {
  const sizeClass: string = sizeMap[size]
  const colorClass = colorMap[color]
  return (
    <button
      disabled={isDisabled}
      {...rest}
      type={type === 'button' ? 'button' : 'submit'}
      className={`py-2 px-6 md:px-7 lg:px-8 xl:px-10 font-light tracking-wide text-base lg:text-lg outline-none  focus-visible:bg-blue  hover:bg-blue transition-colors dutation-300 ease-in-out  ${colorClass} ${sizeClass} `}
    >
      {text}
    </button>
  )
}
