import React, { useEffect, useRef, useState } from 'react'
import * as THREE from 'three'
import ThreeGlobe from 'three-globe'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { markerData } from './utils/markers'
import countries from './utils/globe-data-min.json'
import { africa, india, westerns } from './utils/highlighted-countries'

// import map from 'assets/home/map.png'
import one from 'assets/home/no01.svg'
import two from 'assets/home/no02.svg'
import three from 'assets/home/no03.svg'

const defaultColor = 'rgba(0, 0, 0, 0.4)'
const highlightedColor = 'rgb(235, 131, 52)'
let Globe: any, scene: any, controls: any

const cards = [
  {
    text: 'Export to 20 countries in Southeast Asia, Western Europe, Latin America, Africa, Middle East',
    icon: one,
    id: 'one',
  },
  {
    text: 'Subsidiaries in Indonesia, Brazil, Russia and Singapore',
    icon: two,
    id: 'two',
  },
  {
    text: 'Manufacturing in Asia and Europe',
    icon: three,
    id: 'three',
  },
]

export default function Map() {
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const [currCountry, setCurrCountry] = useState({ country: india, id: 0 })

  useEffect(() => {
    // Sizes
    let width
    if (window.innerWidth < 600) {
      width = window.innerWidth - 50
    } else if (window.innerWidth < 1024) {
      width = window.innerWidth - 200
    } else if (window.innerWidth < 1300) {
      width = 600
    } else if (window.innerWidth < 1500) {
      width = 700
    } else {
      width = 800
    }

    const sizes = {
      width: 600,
      height: 600,
    }

    // Create Globe
    Globe = new ThreeGlobe()
      .showAtmosphere(false)
      .ringsData(markerData)
      .ringColor('ringColor')
      .ringMaxRadius('maxR')
      .ringPropagationSpeed('propagationSpeed')
      .ringRepeatPeriod('repeatPeriod')
      .ringResolution(64)
      .pointsData(markerData)
      .pointAltitude(0.002)
      .pointColor('color')
      .pointRadius('radius')
      .pointResolution(64)
      .hexPolygonsData(countries.features)
      .hexPolygonResolution(0)
      .hexPolygonMargin(0.5)
      .hexPolygonColor((e: any) => {
        if (india.includes(e.properties.ISO_A3)) {
          return highlightedColor
        } else return defaultColor
      })

    const globeMaterial = Globe.globeMaterial()
    globeMaterial.color = new THREE.Color(0xf4f4f4)
    globeMaterial.transparent = true
    globeMaterial.opacity = 0.3

    Globe.rotation.x = 0.3
    Globe.rotation.y = -1.35

    const renderer = new THREE.WebGLRenderer({
      canvas: canvasRef.current as HTMLCanvasElement,
    })
    renderer.setSize(width, width)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

    scene = new THREE.Scene()
    scene.background = new THREE.Color(0xffffff)
    scene.add(Globe)

    scene.add(new THREE.AmbientLight(0xbbbbbb, 1.3))

    const camera = new THREE.PerspectiveCamera(
      45,
      sizes.width / sizes.height,
      1,
      300,
    )
    camera.position.z = 270
    camera.updateProjectionMatrix()

    controls = new OrbitControls(camera, renderer.domElement)
    controls.autoRotate = true
    controls.enableDamping = true
    controls.dynamicDampingFactor = 0.01
    controls.enablePan = true
    controls.minDistance = 101
    controls.maxDistance = 370
    controls.zoomSpeed = 1
    controls.enableZoom = false
    controls.minPolarAngle = Math.PI / 3.5
    controls.maxPolarAngle = Math.PI - Math.PI / 3

    setTimeout(() => {
      Globe.hexPolygonResolution(3)
    }, 3000)

    const animate = () => {
      controls.update()
      renderer.render(scene, camera)
      requestAnimationFrame(animate)
    }

    animate()
    // setTimeout(() => {
    //   animate()
    // }, 3000)
  }, [])

  useEffect(() => {
    controls.reset()

    Globe.hexPolygonColor((e: any) => {
      if (currCountry.country.includes(e.properties.ISO_A3)) {
        return highlightedColor
      } else return defaultColor
    })

    Globe.hexPolygonsData([])

    switch (currCountry.id) {
      case 0:
        Globe.rotation.x = 0.1
        Globe.rotation.y = -0.5
        break

      case 1:
        Globe.rotation.x = 0
        Globe.rotation.y = 1
        break

      case 2:
        Globe.rotation.x = 0.3
        Globe.rotation.y = -1.5
        break

      default:
        break
    }

    setTimeout(() => {
      Globe.hexPolygonsData(countries.features)
    }, 0)
  }, [currCountry])

  function hoverHandler(id: number) {
    switch (id) {
      case 0:
        setCurrCountry({ country: africa, id })
        break

      case 1:
        setCurrCountry({ country: westerns, id })
        break

      case 2:
        setCurrCountry({ country: india, id })
        break

      default:
        setCurrCountry({ country: india, id })
        break
    }
  }

  return (
    <section className="flex flex-col-reverse blade-top-padding blade-bottom-padding-lg lg:grid lg:grid-cols-2 lg:w-container-lg lg:gap-4">
      <section>
        <SectionHeader isShown={'lg'} />
        <div className="border-b-1 border-t-1 pt-10 lg:pt-0 border-solid border-gray lg:border-none ">
          <div className="w-container border-l-1 border-r-1 border-solid border-gray lg:border-none">
            <div className="grid grid-cols-1 gap-y-8  divide-solid divide-gray lg:block">
              {cards.map((elem, index) => {
                const { id, text, icon } = elem
                return (
                  <Card
                    id={id}
                    key={id}
                    text={text}
                    icon={icon}
                    index={index}
                    onHover={hoverHandler}
                  />
                )
              })}
            </div>
          </div>
        </div>
      </section>
      <section>
        <SectionHeader isShown={'sm'} />
        <div className="grid place-content-center h-full lg:pr-16 ">
          <canvas ref={canvasRef}></canvas>
        </div>
      </section>
    </section>
  )
}

function SectionHeader({ isShown }: { isShown: string }) {
  return (
    <div
      className={`${
        isShown === 'lg' ? 'hidden lg:block' : 'lg:hidden'
      }  w-container-sm pt-16 pb-8 lg:py-6 `}
    >
      <h3 className="text-center font-medium pb-2 text-black lg:text-left">
        A step towards global footprint of excellence
      </h3>
      <h5 className=" text-center font-regular text-black lg:text-left pb-6 xl:pb-8 2xl:pb-10">
        Vision 2023-30
      </h5>
    </div>
  )
}

function Card({
  id,
  text,
  icon,
  index,
  onHover,
}: {
  index: number
  id: string
  text: string
  icon: string
  onHover: (id: number) => void
}) {
  return (
    <article
      className="p-3 cursor-pointer transition w-container-sm  md:p-6 lg:p-2  lg:ml-0 hover:text-[#f69a4d] max-w-sm"
      onMouseEnter={() => {
        onHover(index)
      }}
    >
      <div id={id} className="pb-16 pt-2 lg:pb-8 ">
        <img src={icon} loading="lazy" className=" h-12 " alt={text} />
      </div>
      <div className="pb-8">
        <h5 className="font-medium text-[1.3rem]"> {text} </h5>
      </div>
    </article>
  )
}
