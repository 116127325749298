import React, { useEffect } from 'react'
import { BrowserRouter, useRoutes } from 'react-router-dom'

import AOS from 'aos'
import 'aos/dist/aos.css'

import routes from './router'

const RenderRoutes = () => {
  const routeTree = useRoutes(routes())
  return routeTree
}

function App() {
  useEffect(() => {
    AOS.refresh()
    AOS.init({
      duration: 700,
      once: true,
    })
  }, [])
  return (
    <BrowserRouter>
      <RenderRoutes />
    </BrowserRouter>
  )
}

export default App
