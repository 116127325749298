import React, { useEffect, useRef } from 'react'
import awardImage from 'assets/home/frost_award.svg'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

export default function Banner() {
  const wrapper = useRef<any>()
  const overlay = useRef<any>()

  useEffect(() => {
    const ctx = gsap.context(() => {
      const selector = gsap.utils.selector(overlay)
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: wrapper.current,
          start: 'top top',
          end: 'bottom top',
          scrub: 1,
          toggleActions: 'play reverse play reverse',
        },
      })
      tl.to(overlay.current, {
        translateY: '-150%',
      })
    }, wrapper)
    return () => ctx.revert()
  }, [])

  return (
    <section className="">
      <div className="h-screen flex flex-col" ref={wrapper}>
        <div className="flex-1 md:bg-[url('assets/home/banner_bg.png')] bg-[url('assets/home/banner_bg_mobile.png')] bg-cover bg-top bg-no-repeat bg-lightgray">
          <div className="h-[70%] 2xl:h-[56%] bg-banner-overlay" ref={overlay}>
            <div className="w-container h-full gap-y-3 blade-bottom-padding-lg flex  md:flex-row flex-col md:items-end  justify-end md:justify-between  xl:gap-10">
              <div className=" flex flex-col text-wrapper">
                <h6 className="font-medium text-wrapper">
                  Where innovation finds its purpose
                </h6>
                <div className="text-transparent bg-clip-text bg-banner-orange-text font-semibold  text-wrapper text-[3rem] lg:text-[3.5rem] xl:text-[4rem] 2xl:text-[4.8rem] font-regular tracking-wide">
                  <div className=" flex items-center ">
                    <span className="leading-snug">5,00,000</span>
                    <span className="mb-2 2xl:mb-1">+</span>
                  </div>
                </div>
                <h4 className="font-regular text-black text-wrapper">
                  lives touched with cardiac{' '}
                  <br className="md:block xl:hidden block" /> care solutions
                </h4>
              </div>

              <div className="flex items-end gap-1 blade-top-padding max-w-[270px] md:max-w-[300px] 2xl:max-w-md">
                <img
                  src={awardImage}
                  alt="Frost and Sullivan Award won by the Innvolution tech."
                  className="w-full f-full object-cover object-center scale-90 md:scale-100"
                  loading="eager"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
