import React, { useEffect, useRef, useState } from 'react'
import labs from 'assets/home/feature_section/01.png'
import partners from 'assets/home/feature_section/02.png'
import network from 'assets/home/feature_section/03.png'
import cities from 'assets/home/feature_section/04.png'
import downtime from 'assets/home/feature_section/05.png'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import { SwitchTransition, CSSTransition } from 'react-transition-group'

gsap.registerPlugin(ScrollTrigger)

// keep the order in the array and of the blades same
const elements = [labs, partners, network, cities, downtime]

export default function Features() {
  const [viewIndex, setViewIndex] = useState(0)
  const wrapper = useRef<any>()
  const imgWrapper = useRef<any>()
  const cardWrapper = useRef<any>()

  const checking = useRef<any>()

  useEffect(() => {
    const selector = gsap.utils.selector(cardWrapper)
    const ctx = gsap.context(() => {
      const articles = selector('article')
      articles.forEach((elem, index) => {
        gsap.fromTo(
          elem,
          { opacity: 0.2 },
          {
            opacity: 1,
            onStart: () => {
              setViewIndex(index)
            },
            scrollTrigger: {
              trigger: elem,
              start: 'top 50%',
              end: 'bottom 50%',
              toggleActions: 'play reverse play reverse',
            },
          },
        )
      })
    }, wrapper)
    return () => ctx.revert()
  }, [])

  return (
    <section>
      <div className="bg-blue" ref={wrapper}>
        <div className="lg:block hidden">
          <div className="grid grid-cols-2" ref={imgWrapper}>
            <div className="">
              <div className="h-screen sticky top-0">
                <SwitchTransition>
                  <CSSTransition
                    key={viewIndex}
                    nodeRef={checking}
                    timeout={500}
                    classNames="imgslide"
                    unmountOnExit
                    mountOnEnter
                  >
                    <div
                      className="list-none inline h-full w-full"
                      ref={checking}
                    >
                      <img
                        src={elements[viewIndex]}
                        alt="Innvolution"
                        className="h-full w-full object-cover object-center"
                      />
                    </div>
                  </CSSTransition>
                </SwitchTransition>
              </div>
            </div>

            <div>
              <div
                className="cards-wrapper   bg-blue-gradient "
                ref={cardWrapper}
              >
                <div className="sticky top-0 blade-top-padding z-20 text-black text-4xl   bg-blue-gradient ">
                  <h3 className="text-white font-regular  blade-bottom-padding-sm w-10/12 mx-auto leading-tight">
                    Advancing cardiovascular <br className="lg:block hidden" />
                    solution delivery everywhere
                  </h3>
                </div>
                <div>
                  <FeatureCard title="310+" desc="Cath Labs sold" />
                  <FeatureCard title="1400+" desc="hospital partners" />
                  <FeatureCard title="100+" desc="distributor network" />
                  <FeatureCard
                    title="89%"
                    desc="of customer satisfaction across 185+ installations"
                  />
                  <FeatureCard
                    tab=" Less than "
                    title="48 hours"
                    desc=" of downtime at any installation since inception"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" bg-white">
        <div className="grid gap-4 blade-top-padding-lg blade-bottom-padding-lg lg:hidden md:grid-cols-3 w-container ">
          <MobileCard coverImage={labs} title="310+" desc="Cath Labs sold" />
          <MobileCard
            coverImage={partners}
            title="1400+"
            desc="hospital partners"
          />
          <MobileCard
            coverImage={network}
            title="100+"
            desc="distributor network"
          />
          <MobileCard
            coverImage={cities}
            title="89%"
            desc="of customer satisfaction across 185+ installations"
          />
          <MobileCard
            coverImage={downtime}
            title="<48 hours"
            desc=" of downtime at any installation since inception"
          />
        </div>
      </div>
    </section>
  )
}

function FeatureCard({
  tab,
  title,
  desc,
}: {
  tab?: string
  title: string
  desc: string
}) {
  return (
    <article className="border-t-1 border-white border-solid border-opacity-20">
      <div className="flex xl:flex-col flex-col xl:items-start justify-center xl:justify-center  h-80 w-11/12 xl:w-10/12 mx-auto">
        {tab && (
          <h6 className="text-white font-regular font-light leading-relaxed max-w-2xl font-normal">
            {tab}
          </h6>
        )}
        <span className=" text-[6rem] font-semibold font-regular tracking-wide text-white leading-tight">
          {title}
        </span>
        <h4 className="text-white font-regular font-light leading-tight max-w-md font-normal">
          {desc}
        </h4>
      </div>
    </article>
  )
}

function MobileCard({
  title,
  desc,
  coverImage,
}: {
  coverImage: string
  title: string
  desc: string
}) {
  return (
    <article className="max-w-lg mx-auto w-full relative">
      <div className="h-64 sm:h-72 md:h-44 ">
        <img
          src={coverImage}
          className="h-full w-full object-center object-cover"
          alt={title + desc}
        />
      </div>
      <div className="pt-4 pl-2 pb-3 flex  items-end absolute inset-0 bg-gradient-to-b from-transparent to-blue">
        <h3 className="font-medium tracking-wide w-11/12 bg-white bg-clip-text text-transparent  max-w-[280px] leading-tight pb-3 pl-2">
          {title} {desc}
        </h3>
      </div>
    </article>
  )
}
