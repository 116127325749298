import React from 'react'

import { Swiper, SwiperSlide } from 'swiper/react'

import 'swiper/css'
import 'swiper/css/pagination'
import { Pagination } from 'swiper'
import map from 'assets/home/map.png'
import one from 'assets/home/no01.svg'
import two from 'assets/home/no02.svg'
import three from 'assets/home/no03.svg'
import india from 'assets/home/mapMobile/india.png'
import asia from 'assets/home/mapMobile/asia.png'
import indonesia from 'assets/home/mapMobile/indonesia.png'

const cards = [
  {
    text: 'Export to 20 countries in Southeast Asia, Western Europe, Latin America, Africa, Middle East',
    icon: one,
    id: 'one',
    coverImage: india,
  },
  {
    text: 'Subsidiaries in Indonesia, Brazil, Russia and Singapore',
    icon: two,
    id: 'two',
    coverImage: indonesia,
  },
  {
    text: 'Manufacturing in Asia and Europe',
    icon: three,
    id: 'three',
    coverImage: asia,
  },
]

type ICard = (typeof cards)[0]
export default function MapMobile() {
  return (
    <section className="map-wrapper">
      <Swiper autoHeight pagination={true} modules={[Pagination]}>
        {cards.map((elem, index) => {
          const { id } = elem
          return (
            <SwiperSlide key={id} className="px-2">
              <Card data={elem} />
            </SwiperSlide>
          )
        })}
      </Swiper>
    </section>
  )
}
function Card({ data }: { data: ICard }) {
  const { text, icon, coverImage } = data
  return (
    <article className="w-full max-w-lg px-3 pt-7 pb-8">
      <div>
        <img
          src={coverImage}
          alt={text}
          className="h-72 w-full object-contain object-center"
        />
      </div>
      <div className="flex items-start justify-end flex-col pt-5 pb-10 px-3">
        <h4 className="text-black font-regular tracking-wide font-semibold leading-tight">
          {text}
        </h4>
      </div>
    </article>
  )
}
