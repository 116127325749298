import React, { ReactNode } from 'react'
import { TextAnchor } from 'atoms/links'

export default function Footer() {
  return (
    <footer>
      <section className=" font-regular">
        <section className="flex flex-col md:grid xl:gap-y-10 grid-cols-1 md:grid-cols-11 divide-x-1 divide-solid divide-gray  border-t-1 border-solid border-gray">
          <div className="pb-8 md:pb-8 xl:pb-10 pl-3 col-start-1  col-end-2 md:col-end-5">
            <div className=" xl:pt-16  flex md:px-4 pt-10 h-full  flex-col lg:flex-col md:gap-1 2xl:mr-0 lg:mr-auto xl:w-10/12 pr-5 xl:ml-auto">
              <div>
                <span className=" block  border-b-1 border-solid border-gray mb-2 md:mb-3 pb-2 md:pb-3 w-full  md:max-w-[250px] uppercase text-orange font-semibold text-lg ">
                  Contact
                </span>
                <ul className="font-regular py-2 ">
                  <li>
                    <a href="tel:+91 6364882807">+91 6364882807</a>
                  </li>
                </ul>
              </div>
              <div className="pt-6">
                <span className=" block  border-b-1 border-solid border-gray mb-2 md:mb-3 pb-2 md:pb-3 w-full  md:max-w-[250px] uppercase text-orange font-semibold text-lg ">
                  E-mail
                </span>
                <ul className="font-regular py-2 ">
                  <li>
                    <a href="mailto:pinnacle@innvolution.in">
                      pinnacle@innvolution.in
                    </a>
                  </li>
                </ul>
              </div>

              <div className="pt-6">
                <span className=" block  border-b-1 border-solid border-gray mb-2 md:mb-3 pb-2 md:pb-3 w-full  md:max-w-[250px] uppercase text-orange font-semibold text-lg ">
                  Address
                </span>
                <ul className="font-regular py-2 max-w-sm">
                  <li>
                    121 F, Bommasandra Industrial Area, Phase 1, Hulimangla
                    Road, Hosur Main road, Electronic City, Banglore 560 099,
                    Karnataka, India
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className=" col-start-1 md:col-start-5 col-end-12 pr-10 md:pl-4 pb-10 lg:pb-0 ">
            <div className="xl:w-10/12 px-3 mx-auto">
              <div className=" xl:pt-14  md:pt-10 ">
                <span className=" block text-lg  border-b-1 border-solid border-gray mb-2 md:mb-3 pb-2 md:pb-3 w-full  md:max-w-[250px] uppercase text-orange font-semibold">
                  Products
                </span>
                <ul className="font-regular flex-col  flex-wrap md:flex-row flex gap-2 md:gap-6 xl:gap-8 md:py-2 max-w-4xl ">
                  <li>
                    <TextAnchor href="/coming-soon" text="Cath Labs" />
                  </li>
                  <li>
                    <TextAnchor href="/coming-soon" text="Stents" />
                  </li>
                  <li>
                    <TextAnchor href="/coming-soon" text="Balloon Catheters" />
                  </li>
                  <li>
                    <TextAnchor
                      href="/coming-soon"
                      text="Teleport Microcatheter"
                    />
                  </li>
                </ul>
              </div>

              <div className="pt-8">
                <span className=" block  border-b-1 border-solid border-gray mb-2 md:mb-3 pb-2 md:pb-3 w-full  md:max-w-[250px] uppercase text-orange font-semibold text-lg ">
                  Company
                </span>
                <ul className="font-regular grid py-2 md:grid-cols-2 lg:grid-cols-4 gap-2 2xl:max-w-4xl">
                  <li>
                    <TextAnchor href="/coming-soon" text="Who we are" />
                  </li>

                  <li>
                    <TextAnchor href="/coming-soon" text="Services" />
                  </li>

                  <li>
                    <TextAnchor href="/coming-soon" text="Contact Us" />
                  </li>

                  <li>
                    <TextAnchor href="/coming-soon" text="Resources" />
                  </li>
                </ul>
                <ul className="font-regular grid md:py-2 md:grid-cols-2 lg:grid-cols-4 gap-2 2xl:max-w-4xl">
                  <li>
                    <TextAnchor href="/coming-soon" text="Home" />
                  </li>
                  <li>
                    <TextAnchor href="/coming-soon" text="Awards" />
                  </li>
                  <li>
                    <TextAnchor href="/coming-soon" text="Innovation" />
                  </li>
                  <li>
                    <TextAnchor href="/coming-soon" text="Careers" />
                  </li>
                </ul>
              </div>
              <div className="pt-8 xl:pb-24">
                <span className=" block  border-b-1 border-solid border-gray mb-2 md:mb-3 pb-2 md:pb-3 w-full  md:max-w-[250px] uppercase text-orange font-semibold text-lg">
                  Resources
                </span>
                <ul className="font-regular flex gap-8 py-2 max-w-4xl ">
                  <li>
                    <TextAnchor href="/coming-soon" text=" News" />
                  </li>
                  <li>
                    <TextAnchor href="/coming-soon" text="Blogs" />
                  </li>
                  <li>
                    <TextAnchor href="/coming-soon" text="Case studies" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </section>
      <section className="flex flex-col-reverse md:grid xl:gap-y-10 grid-cols-1 md:grid-cols-11 divide-x-1 divide-solid divide-gray  border-t-1 border-solid border-gray">
        <div className=" pb-10 xl:pb-10 md:pt-6 md:pl-3 col-start-1 col-end-2 md:col-end-5">
          <div className=" px-3 md:px-4 h-full 2xl:mr-0 lg:mr-auto xl:w-10/12 pr-5 xl:ml-auto">
            <span className="text-black text-opacity-80 font-regular">
              Copyright © 2023 Innvolution
            </span>
          </div>
        </div>

        <div className=" col-start-1 md:col-start-5 col-end-12 pr-10 md:pl-4 pb-4 md:pb-6 xl:pb-10 pt-6">
          <div className="xl:w-10/12 px-3 mx-auto">
            <div className="flex gap-1">
              <div className="font-regular">
                <TextAnchor text="Privacy Policy" href="/coming-soon" />
              </div>
              <div className="px-1">/</div>
              <div className="font-regular">
                <TextAnchor text="Terms & Conditions" href="/coming-soon" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </footer>
  )
}

function SocialProfile({
  href,
  children,
}: {
  children: ReactNode
  href: string
}) {
  return (
    <a
      href={href}
      rel="noopener"
      className="stroke-white outline-none fill-white hover:fill-yellow focus-visible:fill-yellow hover:stroke-yellow focus-visible:stroke-yellow p-1"
    >
      {children}
    </a>
  )
}
