import React from 'react'
import { TextNavAnchor } from 'atoms/links'
import logo from 'assets/globals/logo.png'

export default function MobileNavModal({
  toggle,
}: {
  toggle: React.DispatchWithoutAction
}) {
  return (
    <section className="bg-white h-screen flex flex-col">
      <div className="px-3 py-5 flex gap-3  justify-between flex-0">
        <div>
          <img
            src={logo}
            className="h-12 w-auto object-contain object-center"
            alt="Logo of Innvolution"
          />
        </div>

        <button
          onClick={toggle}
          className="rounded-full p-3 bg-lightgray bg-opacity-40 outline-none stroke-black"
          type="button"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="inherit"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>

      <div className="flex flex-col flex-1 gap-1">
        <div className="flex-1">
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
          <ul
            className="flex flex-col gap-3 px-3 pt-8 "
            onClick={toggle}
            onKeyDown={toggle}
          >
            <TextNavAnchor size="3xl" href="/who-we-are" text="Who we are" />
            <TextNavAnchor size="3xl" href="/products" text="Products" />
            <TextNavAnchor
              size="3xl"
              href="services-and-support"
              text="Services & Support "
            />
            <TextNavAnchor size="3xl" href="innovation" text="Innovation" />
            <TextNavAnchor size="3xl" href="resources" text="Resources" />
          </ul>
        </div>
      </div>
    </section>
  )
}
