import React, { useState, useEffect } from 'react'
import { z } from 'zod'
import { SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { FilledBtn } from 'atoms/buttons'
import leavesLeft from 'assets/home/leaves_left.svg'
import leavesRight from 'assets/home/leaves_right.svg'

// import AOS from 'aos'
// import 'aos/dist/aos.css'

import award1 from 'assets/home/awards/awards-01.png'
import award2 from 'assets/home/awards/awards-02.png'
import award3 from 'assets/home/awards/awards-03.png'
import award4 from 'assets/home/awards/awards-04.png'
import award5 from 'assets/home/awards/awards-05.png'
import award6 from 'assets/home/awards/awards-06.png'
import award7 from 'assets/home/awards/awards-07.png'
import award8 from 'assets/home/awards/awards-08.png'
import award9 from 'assets/home/awards/awards-09.png'
import award10 from 'assets/home/awards/awards-10.png'
import award11 from 'assets/home/awards/awards-11.png'
import award12 from 'assets/home/awards/awards-12.png'
import award13 from 'assets/home/awards/awards-13.png'

const awards = [
  award1,
  award2,
  award3,
  award4,
  award5,
  award6,
  award7,
  award8,
  award9,
  award10,
  award11,
  award12,
  award13,
  '',
  '',
  '',
]

export default function Awards() {
  // useEffect(() => {
  //   AOS.refresh()
  //   AOS.init({
  //     duration: 700,
  //     delay: 400,
  //     once: true,
  //   })
  // }, [])

  return (
    <section className=" font-regular">
      <section className="grid grid-cols-1 xl:grid-cols-12 divide-x-1 divide-solid divide-gray ">
        <div className="col-start-1  col-end-2 xl:col-end-6  blade-top-padding-lg blade-bottom-padding-lg  bg-orange ">
          <div className=" gap-2 lg:gap-4 flex flex-col h-full w-11/12 lg:w-10/12 xl:w-10/12 2xl:w-9/12 mx-auto md:ml-auto">
            <div
              data-aos="fade-up"
              className="flex gap-4 blade-top-padding blade-bottom-padding-sm max-w-[150px] lg:max-w-[160px]"
            >
              <div className="flex-0 h-full">
                <img
                  src={leavesLeft}
                  alt="Leaves design showing the number of awards Innvolution has received"
                />
              </div>

              <div className="flex-1 justify-center text-center flex-col flex items-center gap-1">
                <h2 className="font-semibold text-white tracking-wider md:block hidden">
                  13
                </h2>
                <h1 className="font-semibold text-white tracking-wider md:hidden block">
                  13
                </h1>
              </div>

              <div className="flex-0">
                <img
                  src={leavesRight}
                  alt="Leaves design showing the number of awards Innvolution has received"
                />
              </div>
            </div>

            <h3
              data-aos="fade-up"
              data-aos-delay="100"
              data-aos-duration="700"
              className="leading-tight font-medium  tracking-wide flex-1 text-white"
            >
              Asia&apos;s most awarded cath lab manufacturing company
            </h3>
          </div>
        </div>
        <div className=" col-start-1 xl:col-start-6 col-end-13 border-t-1 border-solid border-gray">
          <div className="p-4 2xl:p-10">
            <div className=" sm:w-11/12 md:w-full mx-auto">
              <ul className="font-semibold">
                {[...Array(4)].map((row, elemIndex) => {
                  console.log(elemIndex)
                  const key = `${elemIndex}`
                  return (
                    <div
                      key={key}
                      className="grid grid-cols-2 md:grid-cols-4 md:border-b-1 last-of-type:border-none border-black border-opacity-20 border-dashed md:divide-x-1 divide-dashed divide-opacity-20 divide-black"
                    >
                      {awards
                        .slice(elemIndex * 4, elemIndex * 4 + 4)
                        .map((final, index: number) => {
                          const elemKey = `${index}`
                          return (
                            <li
                              data-aos="zoom-in"
                              data-aos-delay={index * 100}
                              data-aos-duration="500"
                              key={elemKey}
                              className="bg-white p-5 2xl:p-9"
                            >
                              {final === '' ? (
                                ''
                              ) : (
                                <img
                                  src={final}
                                  alt="Award received by Innvolution"
                                  className="w-full h-full object-contain object-center"
                                />
                              )}
                            </li>
                          )
                        })}
                    </div>
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
      </section>
    </section>
  )
}

/*


 <div>
              <ul className="font-semibold">
                {[...Array(4)].map((row, elemIndex) => {
                  console.log(elemIndex)
                  const key = `${elemIndex}`
                  return (
                    <div
                      key={key}
                      className="grid grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 border-b-1 last-of-type:border-none  border-black divide-x-1 divide-solid divide-black"
                    >
                      {awards
                        .slice(elemIndex * 4, elemIndex * 4 + 4)
                        .map((final, index: number) => {
                          const elemKey = `${index}`
                          return (
                            <li
                              key={elemKey}
                              className={
                                index === 0 && elemIndex === 3
                                  ? 'border-r-1 border-solid border-black'
                                  : ''
                              }
                            >
                              <img
                                src={final}
                                alt="Award received by Innvolution"
                                className="w-full h-full object-contain object-center"
                              />
                            </li>
                          )
                        })}
                    </div>
                  )
                })}
              </ul>
            </div>

*/
