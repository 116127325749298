import React, { useEffect, useLayoutEffect } from 'react'
import ContactUs from 'organisms/contact_us'
import { gsap } from 'gsap'
import Banner from './banner'
import Products from './products'
import Features from './features'
import Awards from './awards'
import Map from './map'
import News from './news'
import Standards from './standards'
import Stats from './stats'
import Clientele from './clientele'
import Testimonails from './testimonails'
import MapMobile from './mapMobile'
import './home.css'

export default function HomePage() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <main>
      <Banner />
      <Products />
      <Awards />
      <Features />
      <Standards />
      <div className="h-32" />

      <div className="lg:block hidden">
        <Map />
      </div>
      <div className="lg:hidden block">
        <MapMobile />
      </div>
      <Stats />
      <Testimonails />
      <Clientele />
      <News />
      <ContactUs />
    </main>
  )
}
