import React, { useRef, useReducer } from 'react'
import { SwitchTransition, CSSTransition } from 'react-transition-group'
import plusIcon from 'assets/home/plus.svg'
import { MasterBtn } from 'atoms/buttons'

// borrowed from the  general awards section
import award1 from 'assets/home/awards/awards-01.png'
import award2 from 'assets/home/awards/awards-02.png'
import award3 from 'assets/home/awards/awards-03.png'
import award4 from 'assets/home/awards/awards-04.png'

// spefic to prouct section
import stents from 'assets/home/product_section/stent.png'
import cathlabImage from 'assets/home/product_section/cath.png'
import ballon from 'assets/home/product_section/balloon.png'

import stentAward1 from 'assets/home/product_section/product_awards/stent01.png'
import stentAward2 from 'assets/home/product_section/product_awards/stent02.png'
import stentAward3 from 'assets/home/product_section/product_awards/stent03.png'
import stentAward4 from 'assets/home/product_section/product_awards/stent04.png'
import stentAward5 from 'assets/home/product_section/product_awards/stent05.png'
import ballonAward1 from 'assets/home/product_section/product_awards/ballon-01.png'
import ballonAward2 from 'assets/home/product_section/product_awards/ballon-02.png'
import ballonAward3 from 'assets/home/product_section/product_awards/ballon-03.png'
import ballonAward4 from 'assets/home/product_section/product_awards/ballon-04.png'
import ballonAward5 from 'assets/home/product_section/product_awards/ballon-05.png'
import cathlabAward1 from 'assets/home/product_section/product_awards/cath1.png'
import cathlabAward2 from 'assets/home/product_section/product_awards/cath2.png'
import cathlabAward3 from 'assets/home/product_section/product_awards/cath3.png'
import cathlabAward4 from 'assets/home/product_section/product_awards/cath4.svg'
import cathlabAward5 from 'assets/home/product_section/product_awards/cath5.png'

const tabs = [
  {
    awardsTitle: 'Family of Cath Labs',
    target: 'Cath Labs',
    title: 'Cath Labs',
    subtitle: 'Engineered to elevate medical imaging.',
    productsAwards: [
      cathlabAward5,
      cathlabAward1,
      cathlabAward2,
      cathlabAward3,
      cathlabAward4,
    ],
    cover: cathlabImage,
    points: [
      'Design and manufacturing excellence',
      'Cutting-edge cardiology software',
      'State-of-the-art X-Ray imaging',
    ],
    awards: [award4, award1, award2, award3],
  },
  {
    awardsTitle: 'Family of Coronary Stents',
    target: 'Stents',
    isVideo: false,
    cover: stents,
    title: 'Stents',
    subtitle: 'Engineered to deliver safety and efficacy.',
    productsAwards: [
      stentAward1,
      stentAward2,
      stentAward3,
      stentAward4,
      stentAward5,
    ],
    points: [
      'Superior technology',
      'Deliverability redefined',
      'Flexibility like never before',
    ],
    awards: [],
  },
  {
    awardsTitle: 'Family of Balloon Catheters',
    cover: ballon,
    isVideo: false,
    target: 'Balloon Catheters',
    title: 'Balloon Catheters',
    subtitle: 'Engineered to deliver precise navigation.',
    productsAwards: [
      ballonAward1,
      ballonAward2,
      ballonAward3,
      ballonAward4,
      ballonAward5,
    ],
    points: [
      'Truly controlled compliance',
      'Safety, accuracy and minimal friction',
      'Excellent deliverability',
    ],
    awards: [],
  },
]

export default function Products() {
  const [viewIndex, update] = useReducer((s: number, u: number) => u, 0)

  const nodeRef = useRef<any>()
  const imgRef = useRef<any>()
  return (
    <section
      className="min-h-screen relative products- overflow-hidden "
      id="products"
      style={{
        background:
          'linear-gradient(180deg, rgba(0, 54, 214, 0.90) -12.82%, rgba(0, 24, 80, 0.00) 50%)',
      }}
    >
      <section className="blade-top-padding blade-bottom-padding-sm grid place-content-center text-center  ">
        <h3 className="font-medium leading-snug pl-1 text-white mx-auto  md:pl-6 xl:pl-3 w-10/12">
          Embrace a new era of cardiovascular health
        </h3>
        <h4 className="font-medium leading-snug pl-1 text-black md:pl-6 xl:pl-3 mt-4 md:mt-7 xl:mt-10">
          Interventional Solutions
        </h4>
        <div className="mx-auto  md:w-full border-1 border-solid border-[#DFDFDF] max-w-lg w-[90%] items-center justify-between  px-3  md:px-0  flex  py-3 rounded-lg bg-[#F7F7F7] mt-4 md:mt-5 xl:mt-6">
          {tabs.map((elem, index: number) => {
            const key = `${index}`
            return (
              <button
                type="button"
                onClick={() => update(index)}
                className={`px-3 md:px-4 py-2 ${
                  viewIndex === index
                    ? 'bg-white border-1 border-solid border-[#DFDFDF]'
                    : ''
                } font-semibold tracking-wide text-black font-regular md:mx-3 text-sm md:text-base rounded-md lg:text-lg `}
                key={key}
              >
                {elem.title}
              </button>
            )
          })}
        </div>
      </section>

      <section className="flex blade-top-padding-sm  flex-col md:flex-row relative gap-y-10 z-40 px-3 md:px-10 lg:px-12 xl:px-14 2xl:px-20">
        <section className=" basis-20  lg:basis-64 2xl:basis-96 grow shrink  flex items-center">
          <section className="grid place-content-center">
            <SwitchTransition>
              <CSSTransition
                key={viewIndex}
                nodeRef={imgRef}
                timeout={500}
                classNames="imgslide"
                unmountOnExit
              >
                <div
                  ref={imgRef}
                  className="h-full mx-auto w-full xl:w-[85%]  2xl:w-10/12 sm:w-11/12 grid place-content-center"
                >
                  <div className="h-full w-full grid place-content-center">
                    <img
                      src={tabs[viewIndex].cover}
                      alt={tabs[viewIndex].title}
                      className="h-full w-full object-cover object-center"
                    />
                  </div>
                </div>
              </CSSTransition>
            </SwitchTransition>
          </section>
        </section>
        <section className="basis-0 pb-10 grow shrink">
          <SwitchTransition>
            <CSSTransition
              key={viewIndex}
              nodeRef={nodeRef}
              timeout={500}
              classNames="imgslide"
              unmountOnExit
            >
              <div ref={nodeRef}>
                <h3 className="bg-text-dark text-transparent bg-clip-text font-medium">
                  {tabs[viewIndex].title}
                </h3>
                <h6 className="font-regular text-black text-opacity-60">
                  {tabs[viewIndex].subtitle}
                </h6>
                <ul className="list-none pt-6 pb-5 md:pb-2 2xl:pt-10 grid xl:grid-cols-3  grid-cols-1 md:grid-cols-2 ">
                  {tabs[viewIndex].points.map((point, elemIndex: number) => {
                    const elemKey = `${elemIndex}`
                    return (
                      <li
                        key={elemKey}
                        className="flex items-center gap-3 font-regular product-feature-card md:px-3 2xl:px-7  lg:pt-4 2xl:pt-6 pb-4 2xl:pb-7"
                      >
                        <img
                          src={plusIcon}
                          alt="Plus Icon"
                          aria-hidden
                          className="md:hidden block h-4"
                        />
                        <span className="  text-sm lg:text-base 2xl:text-lg">
                          {point}
                        </span>
                      </li>
                    )
                  })}
                </ul>

                <div className=" xl:pt-6 2xl:pt-10">
                  <div className="flex items-center gap-4  max-w-md">
                    <span className="text-black text-opacity-70 font-regular flex-0 text-lg">
                      {tabs[viewIndex].awardsTitle}
                    </span>
                    <div className="bg-gray h-[1px] flex-1" />
                  </div>

                  <div className="lg:flex px-3   grid grid-cols-2 sm:grid-cols-3  items-center pt-6 gap-y-8 lg:gap-3 xl:gap-6 2xl:gap-10 pb-12">
                    {tabs[viewIndex].productsAwards.map(
                      (logo, logoIndex: number) => {
                        const logoKey = `${logoIndex}`
                        return (
                          <div key={logoKey}>
                            <img src={logo} alt={tabs[viewIndex].subtitle} />
                          </div>
                        )
                      },
                    )}
                  </div>
                </div>
                <div className="pt-4">
                  <MasterBtn
                    text="Know More"
                    color="orange"
                    size="large"
                    type="button"
                  />
                </div>
              </div>
            </CSSTransition>
          </SwitchTransition>
        </section>
      </section>
    </section>
  )
}

/*


 useEffect(() => {
    const videoElem = document.getElementById('lab-video')! as HTMLVideoElement
    console.log(videoElem)
    videoElem.play()
  }, [])
  

   <section id="products">
      <div className=" w-[95%] mx-auto 2xl:w-container-lg blade-top-padding blade-bottom-padding">
        <h3 className="font-medium leading-snug pl-1  md:pl-6 xl:pl-3">
          Embrace a new era of cardiovascular health
        </h3>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2">
        <div className="border-t-1 border-solid border-gray ">
          <div className="flex gap-12 xl:max-w-2xl mx-auto md:w-11/12 px-3">
            <div className="pt-8">
              <h4 className="text-orange    mx-auto font-regular">
                Interventional Solutions
              </h4>
            </div>
          </div>
          <div>
            <div>
              <div className="border-b-1 border-solid border-gray  pt-5 2xl:pt-6  pb-7 2xl:pb-10">
                <div className="flex  gap-1 md:gap-5 xl:gap-12 xl:max-w-2xl  mx-auto md:w-11/12 px-3 ">
                  {tabs.map((elem, index: number) => {
                    const key = `${index}`
                    return (
                      <button
                        type="button"
                        onChange={() => update(index)}
                        onClick={() => update(index)}
                        className={` px-3 md:px-4 py-2 ${
                          viewIndex === index ? 'bg-orange' : ''
                        } font-semibold tracking-wide text-black font-regular  text-sm md:text-base rounded-md lg:text-lg `}
                        key={key}
                      >
                        {elem.title}
                      </button>
                    )
                  })}
                </div>
              </div>

              <div className="lg:block hidden">
                <SwitchTransition>
                  <CSSTransition
                    key={viewIndex}
                    nodeRef={nodeRef}
                    timeout={500}
                    classNames="imgslide"
                    unmountOnExit
                  >
                    <div
                      ref={nodeRef}
                      className="outline-none max-w-2xl mx-auto px-3  pt-6 xl:pt-8 md:w-11/12 "
                    >
                      <div>
                        <h3 className="bg-text-dark text-transparent bg-clip-text font-medium">
                          {tabs[viewIndex].title}
                        </h3>
                        <h6 className="font-regular text-black text-opacity-60">
                          {tabs[viewIndex].subtitle}
                        </h6>
                        <ul className="list-none grid gap-6  pt-6 2xl:pt-10">
                          {tabs[viewIndex].points.map(
                            (point, elemIndex: number) => {
                              const elemKey = `${elemIndex}`
                              return (
                                <li
                                  key={elemKey}
                                  className="flex items-center gap-3 font-regular "
                                >
                                  <img
                                    src={plusIcon}
                                    alt="Plus Icon"
                                    aria-hidden
                                  />
                                  <h6>{point}</h6>
                                </li>
                              )
                            },
                          )}
                        </ul>
                        <div className="blade-top-padding-sm">
                          <MasterBtn
                            text="Know More"
                            color="orange"
                            size="large"
                            type="button"
                          />
                        </div>
                        <div className="pt-10">
                          <div className="flex items-center gap-4 ">
                            <span className="text-black text-opacity-30 font-regular flex-0">
                              Family of Cath Labs
                            </span>
                            <div className="bg-gray h-[1px] flex-1" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </CSSTransition>
                </SwitchTransition>
              </div>
            </div>
          </div>
        </div>
        <div className="h-full">
          <div className="flex h-full flex-col">
            <div className="flex-1 min-h-[250px] ">
              <div className=" h-full">
                <SwitchTransition>
                  <CSSTransition
                    key={viewIndex}
                    nodeRef={imgRef}
                    timeout={500}
                    classNames="imgslide"
                    unmountOnExit
                  >
                    <div ref={imgRef} className="h-full w-full">
                      {tabs[viewIndex].isVideo ? (
                        <div className="h-full w-full">
                          <video
                            id="lab-video"
                            loop
                            muted
                            autoPlay
                            className="h-full w-full object-cover object-top"
                          >
                            <source src={tabs[viewIndex].cover} />
                          </video>
                        </div>
                      ) : (
                        <div className="h-full w-full">
                          <img
                            src={tabs[viewIndex].cover}
                            alt={tabs[viewIndex].title}
                            className="h-full w-full object-cover object-center"
                          />
                        </div>
                      )}
                    </div>
                  </CSSTransition>
                </SwitchTransition>
              </div>
            </div>
            <div
              className={` ${
                viewIndex !== 0 ? 'hidden' : ''
              } flex-0 xl:border-l-1 w-11/12 lg:w-full  mx-auto border-solid border-gray`}
            >
              testing
            </div>
            <div>
              <div className=" lg:hidden block outline-none xl:max-w-2xl mx-auto px-3 pt-4  md:pt-6 xl:pt-8 md:w-11/12 ">
                <div>
                  <h3 className="bg-text-dark text-transparent bg-clip-text font-medium">
                    {tabs[viewIndex].title}
                  </h3>
                  <h6 className="font-regular text-black text-opacity-60">
                    {tabs[viewIndex].subtitle}
                  </h6>
                  <ul className="list-none grid gap-6  pt-6  pb-5 mdLpb-2 2xl:pt-10">
                    {tabs[viewIndex].points.map((point, elemIndex: number) => {
                      const elemKey = `${elemIndex}`
                      return (
                        <li
                          key={elemKey}
                          className="flex items-center gap-3 font-regular "
                        >
                          <img
                            src={plusIcon}
                            className="h-5 md:h-auto"
                            alt="Plus Icon"
                            aria-hidden
                          />
                          <h6>{point}</h6>
                        </li>
                      )
                    })}
                  </ul>
                  <div className="blade-top-padding-sm">
                    <MasterBtn
                      text="Know More"
                      color="orange"
                      size="large"
                      type="button"
                    />
                  </div>
                  <div className="pt-10">
                    <div className="flex items-center gap-4 ">
                      <span className="text-black text-opacity-30 font-regular flex-0">
                        Family of Cath Labs
                      </span>
                      <div className="bg-gray h-[1px] flex-1" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


*/
