import React, { useEffect, useState } from 'react'
import { gsap } from 'gsap'

// change the source
import cert1 from 'assets/home/certs/c01.png'
import cert2 from 'assets/home/certs/c02.png'
import cert3 from 'assets/home/certs/c03.png'
import cert4 from 'assets/home/certs/c04.png'
import cert5 from 'assets/home/certs/c05.png'

const clients = [
  cert1,
  cert2,
  cert3,
  cert4,
  cert5,
  cert1,
  cert2,
  cert3,
  cert4,
  cert5,
  cert1,
  cert2,
  cert3,
  cert4,
  cert5,
]

export default function Clientele() {
  return (
    <section className=" font-regular">
      <section className="grid grid-cols-1  md:gap-y-6  gap-y-0 xl:grid-cols-10 divide-x-1 divide-solid divide-gray  border-b-1 border-t-1 border-solid border-gray md:blade-bottom-padding  pb-0 xl:pb-0 2xl:pb-0">
        <div className="col-start-1 grid col-end-2 xl:col-end-4 bg-gray-to-white">
          <div className=" px-3 mx-auto w-11/12 xl:w-10/12 2xl:w-9/12  flex items-center  xl:ml-auto blade-top-padding-sm blade-bottom-padding-sm">
            <h3 className="font-semibold bg-text-dark text-transparent bg-clip-text">
              Brands trust us
            </h3>
          </div>
        </div>

        <div className=" col-start-1 xl:col-start-4 col-end-11 overflow-hidden ">
          <div className="gap-4 md:gap-8 marquee flex items-center">
            {clients.map((elem) => {
              return (
                <div
                  key={elem}
                  className="h-fit basis-64 grid place-content-center px-1 md:px-3 xl:px-5 shrink-0 grow"
                >
                  <img
                    src={elem}
                    aria-hidden
                    alt="Clients of Innvolution"
                    className="h-full w-full object-contain object-center"
                  />
                </div>
              )
            })}
          </div>
        </div>
      </section>
    </section>
  )
}
