import React, { useState, useEffect } from 'react'
import { z } from 'zod'
import { SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { FilledBtn } from 'atoms/buttons'
import logo from 'assets/globals/logo.png'

import AOS from 'aos'
import 'aos/dist/aos.css'

const formSchema = z.object({
  query: z.string().min(10, 'Query is required'),
})

export type FormFieldSchema = z.infer<typeof formSchema>

export default function ContactUs() {
  const [isLoading, setLoading] = useState(false)
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormFieldSchema>({
    resolver: zodResolver(formSchema),
  })
  const onSubmit: SubmitHandler<FormFieldSchema> = async (data) => {
    setLoading(true)
    const res = await fetch('')
    if (res.ok) {
      console.log(data)
      console.log('form has been submitted')
    } else {
      console.error('Form error')
    }
    reset()
    setLoading(false)
  }
  useEffect(() => {
    AOS.init()
  }, [])

  return (
    <section className="">
      <section className="grid  gap-y-10  grid-cols-1 lg:grid-cols-11 divide-x-1 divide-solid divide-gray   border-t-1 border-solid border-gray">
        <div className="col-start-1 col-end-2 lg:col-end-5 blade-top-padding blade-bottom-padding lg:block hidden ">
          <div
            className="grid place-content-center h-full"
            data-aos-delay="50"
            data-aos="zoom-in"
            data-aos-duration="1000"
          >
            <img
              src={logo}
              alt="Innvolutions's logo"
              className="h-24 lg:h-28"
            />
          </div>
        </div>
        <div
          className=" col-start-1 lg:col-start-5 col-end-12 blade-top-padding-lg blade-bottom-padding-lg  bg-white-to-orange md:pl-4"
          style={{ backgroundSize: '100% 400%' }}
        >
          <div className="px-1 md:px-3 w-[95%] xl:w-10/12 mx-auto blade-top-padding-sm blade-bottom-padding-sm">
            <h3 className="pb-1 font-medium font-regular bg-clip-text text-transparent bg-text-dark">
              Always here to help!
            </h3>
            <h5 className="font-medium text-black text-opacity-70 md:block w-11/12 hidden">
              Have a question? Let us know, and we&apos;ll be reaching out.
            </h5>
            <span className="text-sm font-regular leading-tight text-black text-opacity-70 md:hidden w-10/12  block">
              Have a question? Let us know, and we&apos;ll be reaching out.
            </span>
            <div className=" blade-top-padding-sm max-w-xl ">
              <form noValidate onSubmit={handleSubmit(onSubmit)}>
                <div
                  className={` ${
                    errors.query ? 'bg-danger' : 'bg-transparent'
                  } flex `}
                >
                  <input
                    className={` ${
                      errors.query
                        ? 'focus:text-danger text-danger'
                        : 'text-black focus:text-black'
                    } flex-1 placeholder:lightgray font-regular bg-white  border-l-2 border-t-2 border-b-2 border-orange border-solid border-opacity-60 placeholder:font-light tracking-wider placeholder:text-dark py-3 md:py-4 pl-3 md:pl-4 pr-5 outline-none text-sm md:text-lg`}
                    size={1}
                    id=""
                    placeholder="What are you looking for?"
                    {...register('query')}
                  />

                  <FilledBtn
                    color="orange"
                    text="Submit"
                    size="base"
                    type="submit"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </section>
  )
}
