import { MasterBtn } from 'atoms/buttons'
import React, { useEffect } from 'react'
import realtime from 'assets/home/standards_icons/realtime.svg'
import dynamic from 'assets/home/standards_icons/dynamic_coronary.svg'
import bridging from 'assets/home/standards_icons/bridging_gaps.svg'
import revolutionising from 'assets/home/standards_icons/visualisation.svg'
import pioneering from 'assets/home/standards_icons/pioneering.svg'
import lowset_radiation from 'assets/home/standards_icons/lowset_radiation.svg'
import { useNavigate } from 'react-router-dom'

import AOS from 'aos'
import 'aos/dist/aos.css'

const cards = [
  {
    title: 'Real-time Quantitative Coronary Analysis (QCA)',
    icon: realtime,
  },
  {
    title: 'Dynamic coronary roadmap to precision',
    icon: dynamic,
  },
  {
    title: 'Bridging gaps with tele-echocardiography',
    icon: bridging,
  },
  {
    title: 'Revolutionizing visualization with Virtual-FFR and 3D Rendering',
    icon: revolutionising,
  },
  {
    title: 'Pioneering drug-eluting balloons',
    icon: pioneering,
  },
  {
    title: 'Lowest radiation dose across all the Cath Labs',
    icon: lowset_radiation,
  },
]

type ICard = (typeof cards)[0]
export default function Standards() {
  useEffect(() => {
    AOS.init()
  }, [])

  const navigate = useNavigate()
  return (
    <section className="lg:blade-top-padding-lg blade-bottom-padding stats-bg overflow-x-hidden">
      <section className="blade-top-padding-lg blade-bottom-padding px-3 sm:w-container-sm">
        <section className=" blade-bottom-padding flex items-start flex-col gap-3  lg:gap-5 max-w-7xl mx-auto">
          <h1
            data-aos-delay="50"
            data-aos="fade-up"
            data-aos-duration="700"
            className="font-medium leading-tight text-transparent bg-clip-text bg-text-dark xl:block hidden"
          >
            Innovating for hearts, <br className="lg:block hidden" /> evolving
            lives
          </h1>
          <h2
            data-aos-delay="50"
            data-aos="fade-up"
            data-aos-duration="700"
            className="font-medium leading-tight text-transparent bg-clip-text bg-text-dark block xl:hidden"
          >
            Innovating for hearts, <br className="lg:block hidden" /> evolving
            lives
          </h2>

          <h5
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-duration="700"
            className=" font-regular text-black text-opacity-70 pb-3 md:pb-4 md:block hidden"
          >
            We fuel innovation with heartfelt commitment, delivering{' '}
            <br className="lg:block hidden" /> cardiovascular solutions that
            touch countless lives.
          </h5>
          <h6
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-duration="700"
            className=" font-regular text-black text-opacity-70 pb-3 md:pb-4 block md:hidden"
          >
            We fuel innovation with heartfelt commitment, delivering{' '}
            <br className="lg:block hidden" /> cardiovascular solutions that
            touch countless lives.
          </h6>

          <div data-aos-delay="150" data-aos="fade-up" data-aos-duration="700">
            <MasterBtn
              color="orange"
              size="base"
              type="button"
              onClick={() => navigate('/coming-soon')}
              text="Know More"
            />
          </div>
        </section>

        <section className="grid md:grid-cols-2 xl:grid-cols-3 gap-4 xl:gap-8 max-w-7xl mx-auto pt-5">
          {cards.map((elem, index) => {
            const key = `${index}`
            return <Card data={elem} key={key} index={index} />
          })}
        </section>
      </section>
    </section>
  )
}

function Card({ data, index }: { index: number; data: ICard }) {
  const { icon, title } = data
  return (
    <article
      data-aos-delay={100 * index}
      data-aos="zoom-in"
      data-aos-duration="700"
      className=" relative group bg-white border-1 grid max-w-md w-full gap-16 2xl:gap-24 border-gray border-solid  p-5 xl:p-6 2xl:p-10 "
    >
      <div className=" flex-1 ">
        <div className="z-10">
          <img src={icon} alt={title} />
        </div>
      </div>
      <div className="pb-2 flex-0 flex items-end z-10">
        <h5 className="max-w-[230px] md:max-w-[280px] font-regular">
          {title}{' '}
        </h5>
      </div>
    </article>
  )
}

/*


*/
