import React, {
  useReducer,
  useRef,
  useState,
  useEffect,
  MouseEventHandler,
} from 'react'
import logo from 'assets/globals/logo.png'
import { useNavigate, Link } from 'react-router-dom'
import { Transition } from 'react-transition-group'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import { Popover, Transition as PopTransition } from '@headlessui/react'
import { TextNavAnchor } from 'atoms/links'
import MobileNavModal from './mobileNav'

gsap.registerPlugin(ScrollTrigger)

export default function Header() {
  const [mobileNav, toggleMobileNav] = useReducer((s) => !s, false)
  const headerWrapperRef = useRef(null)
  const modalRef = useRef<HTMLDivElement>(null)
  const navigate = useNavigate()

  const servicesBtn = useRef<HTMLButtonElement>(null)
  const productsBtn = useRef<HTMLButtonElement>(null)
  const techBtn = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    if (mobileNav) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [mobileNav])

  useEffect(() => {
    const showAnim = gsap
      .from(headerWrapperRef.current, {
        yPercent: -100,
        paused: true,
        duration: 0.2,
      })
      .progress(1)
    ScrollTrigger.create({
      start: 'top top',
      end: 99999,
      onUpdate: (self) => {
        if (self.direction === -1) {
          showAnim.play()
        } else {
          showAnim.reverse()
        }
      },
    })
  }, [])

  const modalAnimation = (node: any) => {
    const tl = gsap.timeline()
    tl.fromTo(
      node,
      { opacity: 0, zIndex: -1 },
      { opacity: 1, duration: 0.3, zIndex: 200 },
    )
    tl.fromTo(
      modalRef.current,
      {
        opacity: 0,
        xPercent: 100,
      },
      {
        xPercent: 0,
        opacity: 1,
        duration: 0.3,
      },
    )
    return tl
  }

  const popNavigation = (
    target: string,
    popId: 'tech' | 'products' | 'services',
  ) => {
    switch (popId) {
      case 'tech': {
        navigate('/coming-soon')
        // navigate(`/tech/${target}`)
        return techBtn.current?.click()
      }
      case 'products': {
        navigate('/coming-soon')
        // navigate(`/products/${target}`)
        return productsBtn.current?.click()
      }
      case 'services': {
        navigate('/coming-soon')
        // navigate(`/resources/${target}`)
        return servicesBtn.current?.click()
      }
      default:
    }
  }
  return (
    <>
      <Transition
        appear
        timeout={500}
        in={mobileNav}
        onEnter={(node: any) => modalAnimation(node).play()}
        onExit={(node: any) => modalAnimation(node).reverse(0)}
        mountOnEnter
        unmountOnExit
      >
        <div className="fixed top-0 left-0 right-0 h-screen bottom-0 bg-opacity-50 md:hidden block z-[9999]">
          <div ref={modalRef} className="h-full overflow-y-auto z-[9999]">
            <MobileNavModal toggle={toggleMobileNav} />
          </div>
        </div>
      </Transition>

      <header
        ref={headerWrapperRef}
        className="bg-transparent overflow-x-hidden fixed top-0 left-0 right-0 z-50 pt-0 lg:pt-3"
      >
        <nav className="flex flex-nowrap justify-between  items-center top-0 left-0 right-0 bottom-0 gap-5 2xl:gap-6 lg:bg-transparent bg-white  py-2 md:py-3 md:pl-5 w-container-full z-50 w-container-lg 2xl:w-container">
          <div className="flex-1 bg-white flex items-center py-5   self-stretch min-h-full">
            <div className="flex-0 grid px-1 place-content-start md:place-content-center basis-44 2xl:basis-48">
              <Link to="/">
                <img
                  src={logo}
                  className="h-10 md:h-12 w-auto object-contain object-center"
                  alt="Logo of Innvolution"
                />
              </Link>
            </div>
            <div className="flex-1 items-center justify-end pr-10 gap-10 lg:flex hidden">
              <TextNavAnchor size="small" href="/about-us" text="Who we are" />
              <TextNavAnchor size="small" href="/products" text="Products" />
              <TextNavAnchor
                size="small"
                href="services-and-support"
                text="Services & Support "
              />
              <TextNavAnchor size="small" href="innovation" text="Innovation" />
              <TextNavAnchor size="small" href="resources" text="Resources" />
            </div>
          </div>
          <div className="flex-0  min-h-full self-stretch md:block hidden md:basis-44 2xl:basis-48">
            <Link
              to="/coming-soon"
              className="font-medium justify-center h-full font-semibold tracking-wide bg-white stroke-orange hover:stroke-white hover:fill-white fill-orange text-black hover:text-white px-3 py-3 lg:py-5 flex items-center gap-3 hover:bg-orange transition-colors duration-300 ease-in-out text-lg "
            >
              Contact Us
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="inherit"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                />
              </svg>
            </Link>
          </div>
          <div className="lg:hidden block pr-1">
            <button
              onClick={toggleMobileNav}
              className="rounded-full p-3 bg-lightgray bg-opacity-40 outline-none stroke-black"
              type="button"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="inherit"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5"
                />
              </svg>
            </button>
          </div>
        </nav>
      </header>
    </>
  )
}
