import React, { useEffect } from 'react'

import coverImage from 'assets/home/dummyNews.png'
import { Link } from 'react-router-dom'

import AOS from 'aos'
import 'aos/dist/aos.css'
import item1 from 'assets/home/news/03.png'
import item2 from 'assets/home/news/04.png'
import item3 from 'assets/home/news/01.png'
import item4 from 'assets/home/news/02.png'

const blogs = [
  {
    title: 'Innvolution Healthcare receives The 2023 Company of The Year Award',
    tag: 'News',
    cover: item3,
    target:
      'https://www.frost.com/wp-content/uploads/2023/07/IHPL_Award-Writeup.pdf',
  },
  {
    title: 'Innvolution Group raises funds from OrbiMed to accelerate growth',
    tag: 'News',
    cover: item4,
    target:
      'https://www.expresshealthcare.in/news/innvolution-group-raises-funds-from-orbimed-to-accelerate-growth/438775/',
  },

  {
    title:
      'Pioneering cardiac care: Our founder’s vision for a better tomorrow',
    tag: 'Spotlight',
    cover: item1,
    target:
      'https://www.medicalbuyer.co.in/pioneering-cardiac-care-my-vision-revolutionizing-healthcare-for-a-better-tomorrow/',
  },

  {
    title: 'Meet Mr. Gaurav Aggarwal, the driving force behind Innvolution',
    tag: 'Spotlight',
    cover: item2,
    target: 'https://voiceofhealthcare.org/idealleader.php?id=1450',
  },
]
type IBlog = (typeof blogs)[0]

export default function News() {
  useEffect(() => {
    AOS.init()
  }, [])

  return (
    <section className="overflow-x-hidden">
      <section className="w-container-lg blade-top-padding-lg blade-bottom-padding">
        <h3
          data-aos-delay="50"
          data-aos="fade-up"
          data-aos-duration="700"
          className="font-medium pb-2"
        >
          News & Insights
        </h3>
        <h5
          data-aos-delay="100"
          data-aos="fade-up"
          data-aos-duration="700"
          className="text-black text-opacity-80 font-regular  max-w-md xl:max-w-xl leading-tight"
        >
          Stay informed with the latest cardiovascular advancements and industry
          insights.
        </h5>
        <div className=" pb-2 blade-top-padding">
          <div className="grid xl:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-x-5 gap-y-2 md:gap-y-10 xl:gap-3 2xl:gap-4 ">
            {blogs.map((elem: IBlog, index: number) => {
              const key = `${index}`
              return <Card data={elem} key={key} index={index} />
            })}
          </div>
        </div>
      </section>
    </section>
  )
}

function Card({ data, index }: { index: number; data: IBlog }) {
  const { cover, title, target, tag } = data
  return (
    <article
      data-aos-delay={100 * index}
      data-aos="zoom-in"
      data-aos-duration="700"
      className="border-1 border-solid border-gray max-w-md mx-auto flex flex-col"
    >
      <div className=" h-56 2xl:h-72">
        <img
          src={cover}
          className="h-full w-full object-cover object-top "
          loading="lazy"
          alt={title}
        />
      </div>
      <div className="flex-1 flex flex-col gap-1 pb-3 pt-2 2xl:pt-4 px-4 ">
        <span className="text-orange font-regular tracking-wider">{tag}</span>
        <span className="flex-1 text-base md:text-xl leading-tight font-regular">
          {title}
        </span>
        <a
          className="outline-none font-medium gap-2 hover:stroke-blue fill-black focus-visible:stroke-blue group focus:text-blue hover:text-blue font-semibold text-lg underline decoration-from-font underline-offset-4 flex items-center flex-0 rounded-full py-3 mr-auto mt-2 "
          href={target}
          target="_blank"
          rel="noreferrer"
        >
          Read More
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            className=" h-4 w-4 opacity-0 group-focus:opacity-100 group-hover:opacity-100 transition-all duration-300 ease-in-out"
            strokeWidth={1.5}
            stroke="inherit"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
            />
          </svg>
        </a>
      </div>
    </article>
  )
}
