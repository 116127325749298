export const africa = [
  'DZA',
  'LBY',
  'AGO',
  'BEN',
  'BWA',
  'BFA',
  'BDI',
  'CMR',
  'CPV',
  'CAF',
  'TCD',
  'COM',
  'COG',
  'COD',
  'CIV',
  'DJI',
  'EGY',
  'GNQ',
  'ERI',
  'ETH',
  'GAB',
  'GMB',
  'GHA',
  'GIN',
  'GMB',
  'KEN',
  'LSO',
  'LBR',
  'LBY',
  'MLI',
  'MWI',
  'MRT',
  'MUS',
  'MYT',
  'MAR',
  'MOZ',
  'NAM',
  'NER',
  'NGA',
  'REU',
  'RWA',
  'STP',
  'SEN',
  'SYC',
  'SLE',
  'SOM',
  'ZAF',
  'SSD',
  'SDN',
  'SWZ',
  'TZA',
  'TGO',
  'TUN',
  'UGA',
  'ESH',
  'ZMB',
  'ZWE',
]

export const india = ['IND']

export const westerns = [
  'MEX',
  'GTM',
  'BLZ',
  'SLV',
  'HND',
  'NIC',
  'CRI',
  'PAN',
  'CUB',
  'JAM',
  'HTI',
  'DOM',
  'VEN',
  'SUR',
  'GUF',
  'COL',
  'ECU',
  'GUY',
  'BRA',
  'PER',
  'BOL',
  'PRY',
  'URY',
  'CHL',
  'ARG',
  'FRA'
]
