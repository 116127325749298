import React, { useRef, useEffect } from 'react'
import cardCover4 from 'assets/home/purpose/img01.png'
import cardCover5 from 'assets/home/purpose/img02.png'
import cardCover2 from 'assets/home/purpose/img03.png'
import cardCover3 from 'assets/home/purpose/img04.png'
import cardCover1 from 'assets/home/purpose/img05.png'

import AOS from 'aos'
import 'aos/dist/aos.css'

import { gsap } from 'gsap'

const dataPoints = [
  {
    stat: '500000+',
    desc: 'lives touched',
    coverImage: cardCover4,
  },
  {
    stat: '170 cities',
    desc: 'and 21 states reached in India',
    coverImage: cardCover2,
  },
  {
    stat: '240 Cath Labs',
    desc: 'manufactured annually',
    coverImage: cardCover5,
  },
  {
    stat: '766+ districts',
    desc: 'to get Innvolution Cath Labs installed by 2030',
    coverImage: cardCover3,
  },
  {
    stat: 'Advancing Aatmanirbhar ',
    desc: 'vision in the MedTech innovation',
    coverImage: cardCover1,
  },
]

type ICard = (typeof dataPoints)[0]

export default function Stats() {
  const wrapperRef = useRef<any>(null)
  const divRef = useRef<any>(null)
  const carouselWrapper = useRef<any>(null)

  useEffect(() => {
    const maxRot = 30
    function mouseMoveFunc(evt: any) {
      const maxX: any = gsap.getProperty('.carousel-wrapper article', 'width')
      const percent = gsap.utils.normalize(0, window.innerWidth, evt.pageX)
      console.log(maxX)

      gsap.to('.carousel-wrapper', {
        duration: 0.4,
        x: -percent * maxX * 2.5,
        overwrite: true,
      })
    }
    carouselWrapper.current.addEventListener('mousemove', mouseMoveFunc)

    window.addEventListener('resize', () => {
      if (window.innerWidth < 768) {
        carouselWrapper.current.removeEventListener('mousemove', mouseMoveFunc)
      }
    })
  }, [])

  useEffect(() => {
    AOS.init()
  }, [])

  return (
    <section ref={wrapperRef}>
      <section className=" bg-blue   blade-bottom-padding">
        <div className="px-3 md:w-11/12 xl:w-[82%] 2xl:w-[80%] mx-auto blade-top-padding-sm">
          <div className="blade-top-padding blade-bottom-padding-lg grid gap-2 md:gap-3 lg:gap-4">
            <h3
              data-aos-delay="50"
              data-aos="fade-up"
              data-aos-duration="700"
              className=" font-medium text-white"
            >
              Acting with a purpose
            </h3>
            <h5
              data-aos-delay="100"
              data-aos="fade-up"
              data-aos-duration="700"
              className="text-white font-light tracking-wider  max-w-lg xl:max-w-xl md:block hidden"
            >
              We&apos;re on a mission to democratize access to advanced
              cardiovascular care, bringing hope and healing around the world.
            </h5>
            <span
              data-aos-delay="100"
              data-aos="fade-up"
              data-aos-duration="700"
              className="text-white font-light tracking-wider xl:max-w-xl md:hidden block text-sm leading-tight w-11/12  max-w-lg"
            >
              We’re on a mission to democratize access to advanced
              cardiovascular care, bringing hope and healing around the world.
            </span>
          </div>
        </div>
        <div className="" ref={carouselWrapper}>
          <div className="w-[90%] mouse-carousel-wrapper ml-auto overflow-hidden xl:blade-bottom-padding-sm">
            <div
              ref={divRef}
              className="pl-4 carousel-wrapper hidden lg:grid px-10 cols-stats gap-5 lg:grid-cols-md-slider xl:grid-cols-large-slider 2xl:grid-cols-xl-slider"
            >
              {dataPoints.map((elem: ICard, index: number) => {
                const key = `${index}`
                return <Card data={elem} key={key} />
              })}
            </div>
          </div>
        </div>

        <div className="overflow-hidden">
          <div className=" grid md:grid-cols-2 grid-cosl-1 gap-y-10 gap-x-5 w-container max-w-5xl mx-auto lg:hidden">
            {dataPoints.map((elem: ICard, index: number) => {
              const key = `${index}`
              return <Card data={elem} key={key} />
            })}
          </div>
        </div>
      </section>
    </section>
  )
}

function Card({ data }: { data: ICard }) {
  const { stat, desc, coverImage } = data
  return (
    <article className="w-full max-w-lg">
      <div className="md:h-72 h-56 xl:h-[320px] 2xl:h-[450px]">
        <img
          src={coverImage}
          alt={stat + desc}
          className="h-full w-full object-cover object-top"
        />
      </div>
      <div className=" text-white flex items-start justify-end flex-col  pt-4 px-2">
        <h4 className="text-white font-regular tracking-wide font-semibold leading-tight md:block hidden">
          {stat}
        </h4>
        <h3 className="text-white font-regular tracking-wide font-semibold leading-tight md:hidden block">
          {stat}
        </h3>
        <h6 className="text-white font-light  w-11/12  leading-tight">
          {desc}
        </h6>
      </div>
    </article>
  )
}
